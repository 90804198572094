<template>
<div>
    <b-container>
        <div class='main-loader' v-if='isLoading'></div>

        </loading-overlay>
        <form-wizard ref="checkoutWizard" color="#212121" :title="null" :subtitle="null" finish-button-text="Submit" back-button-text="Previous" hide-buttons class="checkout-form-wizard steps-transparent mt-1 mt-md-2">
            <!-- account detail tab -->
            <tab-content title="Order Summary" icon="feather icon-shopping-cart" class="mb-5">

                <div class="list-view product-checkout mt-0">

                    <!-- Products List -->
                    <div class="checkout-items">

                        <b-card>
                            <!-- media -->
                            <b-media no-body>
                                <b-media-aside>
                                    <b-link>
                                        <b-avatar rounded :src="userData.profile_url" :text="avatarText(userData.full_name)" variant="light-primary" size="80px" />

                                    </b-link>
                                    <!--/ avatar -->
                                </b-media-aside>
                                <b-media-body class="mt-0 ml-75">
                                    <h4>{{ userData.full_name }}</h4>
                                    <p class="mb-25"><span class="font-weight-bolder">User ID:</span> <span class="ml-50">{{ userData.user_id }}</span></p>
                                    <!--   <p class="mb-25"><span class="font-weight-bolder">User Role:</span> <span class="ml-50 text-danger">{{ userData.user_role.toUpperCase() }}</span></p>-->
                                    <p class="mb-25"><span class="font-weight-bolder">E-mail Address:</span> <span class="ml-50">{{userData.email}}</span></p>
                                    <!--   <p class="mb-0"><span class="font-weight-bolder">Status:</span> <span class="ml-50 text-success"> {{ userData.user_account_status==1?'Active':'Inactive'}}</span></p>-->

                                </b-media-body>
                            </b-media>
                            <!--/ media -->

                        </b-card>

                        <b-card no-body id='addressCard'>
                            <b-card-header class="d-flex justify-content-between align-items-center">
                                <b-card-title>Choose a address</b-card-title>
                                <!-- <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" size="sm" v-b-modal.Add-new-address>
                                    Add a new address
                                </b-button> -->
                            </b-card-header>
                            <b-card-body>
                                <!-- <b-row>
                                <b-col cols="12" md="12"> -->
                                <h5>Your address</h5>
                                <div class="mt-1">
                                    <div class="">

                                        <b-form-radio v-model="Selected" name="some-radios" value="1" class="mb-2">
                                            <div class="d-md-flex align-items-center">
                                                <div class="mb-50 mb-md-0">
                                                    <feather-icon class="text-primary mr-25" icon="MapPinIcon" size="16" />
                                                    <span> {{ mainAddress.address }} </span>
                                                </div>
                                                <span class="ml-md-75">
                                                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class='' variant="outline-primary" size="sm" @click="editMainAddress()">
                                                        Main address
                                                    </b-button>
                                                </span>
                                            </div>
                                        </b-form-radio>

                                        <b-form-radio v-model="Selected" name="some-radios" value="2" class="mb-1 mt-1">
                                            <div class="d-md-flex align-items-center">
                                                <div class="mb-50 mb-md-0">
                                                    <feather-icon class="text-primary mr-25" icon="HomeIcon" size="16" />
                                                    <span>{{shippingAddress.address}}</span>
                                                </div>
                                                <span class="ml-md-75">
                                                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" size="sm" @click="editshippingAddress()">
                                                        <!-- <feather-icon icon="CreditCardIcon" size="18" /> -->
                                                        Shipping address
                                                    </b-button>
                                                </span>
                                            </div>
                                        </b-form-radio>

                                    </div>
                                </div>
                                <!-- </b-col>
                            </b-row> -->

                                <!-- Edit address Popup -->
                                <b-modal id="edit-address" title="Edit address" size="lg">
                                    <div class="p-1">
                                        <b-row>
                                            <b-col md="12">
                                                <div class="
                              tab-change-pwd
                              md:ml-4 md:mt-0
                              mb-2
                              ml-0
                              text-center
                            ">
                                                    <vue-google-autocomplete id="from_address" :country="[]" classname="w-full" placeholder="Search Location" v-on:placechanged="getAddressData" class="search-location" v-model="addressFormat"></vue-google-autocomplete>
                                                </div>
                                            </b-col>

                                            <b-col cols="12" md="6">
                                                <b-form-group>
                                                    <b-form-input id="mc-unit" placeholder="Unit No" v-model="unit_no" />
                                                </b-form-group>
                                            </b-col>

                                            <b-col cols="12" md="6">
                                                <b-form-group>
                                                    <b-form-input id="mc-street_no" placeholder="Street No" v-model="street_no" />
                                                </b-form-group>
                                            </b-col>

                                            <b-col cols="12" md="6">
                                                <b-form-group>
                                                    <b-form-input id="mc-street_name" placeholder="Street Name" v-model="street_name" />
                                                </b-form-group>
                                            </b-col>

                                            <b-col cols="12" md="6">
                                                <b-form-group>
                                                    <b-form-input id="mc-suburb" placeholder="Suburb" v-model="suburb" />
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="12" md="6">
                                                <b-form-group>
                                                    <b-form-input id="mc-city" placeholder="Town/City" v-model="city" />
                                                </b-form-group>
                                            </b-col>

                                            <b-col cols="12" md="6">
                                                <b-form-group>
                                                    <b-form-input id="mc-state" placeholder="State" v-model="state" />
                                                </b-form-group>
                                            </b-col>

                                            <b-col cols="12" md="6">
                                                <b-form-group>
                                                    <b-form-input id="mc-country" placeholder="Country" v-model="country" />
                                                </b-form-group>
                                            </b-col>

                                            <b-col cols="12" md="6">
                                                <b-form-group>
                                                    <b-form-input id="mc-postal_code" placeholder="Postal Code" v-model="postal_code" />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </div>

                                    <template v-slot:modal-footer="{}">
                                        <b-button variant="success" @click="insertUpdateAddress()">Update</b-button>
                                        <b-button variant="danger" @click="cancelEdit()">Cancel</b-button>
                                    </template>
                                </b-modal>
                                <!-- End Add a new address Popup -->
                            </b-card-body>
                        </b-card>

                        <b-card v-for="item in cartData" id='itemCard' :key="item.text" class="ecommerce-card" no-body>

                            <!-- Product Image -->
                            <div class="item-img">
                                <b-link>
                                    <b-img :src="item.default_img" class="" alt="" />
                                </b-link>
                            </div>

                            <!-- Product Details: Card Body -->
                            <b-card-body>
                                <div class="item-name">
                                    <h6 class="mb-0">
                                        <b-link v-if='item.item_type=="1"' :to="{ name: 'events-page', params: {id:item.item_id } }">
                                            {{ item.item_name }}
                                        </b-link>
                                        <b-link v-if='item.item_type=="2"' :to="{ name: 'product-details', query: {id:item.item_id } }">
                                            {{ item.item_name }}
                                        </b-link>
                                    </h6>
                                    <p>{{ item.short_description }}</p>
                                    <span class="text-success mb-50" v-if="item.item_type == '1'">{{ item.is_closed_open }}</span>
                                    <p class="mb-50" v-if="item.item_type == '1'">
                                        <span>Event Type:</span><span class="text-danger mt-50 ml-50" v-if="item.item_type == '1'">{{ item.item_type_name }}</span>
                                    </p>
                                    <p v-if="item.item_type == '1'">
                                        <span>Event Format:</span><span class="text-info mt-50 ml-50" v-if="item.item_type == '1'">{{
                          item.item_method == '1' ? "Online" : "Face to Face"
                        }}</span>
                                    </p>
                                    <span class="text-success" v-if="item.item_type == '2' && item.item_method == '2'">
                                        {{
                        item.item_is_in_stok == "1"
                          ? "In stock"
                          : "Out Of Stock"
                      }}
                                    </span>
                                </div>

                                <div class="item-quantity mt-75" v-if="item.item_type == '2'">
                                    <span class="quantity-title">Qty:</span>
                                    <!--:value="item.item_qty"-->
                                    <b-form-spinbutton size="sm" class="ml-75" inline wrap min="1" :value="item.item_qty" :max="item.item_remaining_stock" @input="addQuantity($event, item)" />

                                </div>
                            </b-card-body>

                            <!-- Product Options/Actions -->
                            <div class="item-options text-center">
                                <div class="item-wrapper">
                                    <div class="item-cost">

                                        <div v-if='item.item_type=="2"'>
                                            <h4 v-if='(userData.user_role=="nonmember")&&(item.is_discount_price==1)'><span class="text-success">${{parseFloat(item.nonmemberprice).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</span> <span class="text-danger font-weight-normal ml-50 line-through">${{item.nonmember_price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</span></h4>
                                            <h4 v-if='(userData.user_role=="nonmember")&&(item.is_discount_price==0)'> <span class="text-success font-weight-normal ml-50 ">${{item.nonmemberprice.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</span></h4>
                                        </div>

                                        <div v-if='item.item_type=="2"'>
                                            <h4 v-if='(userData.user_role=="member")&&(item.is_discount_price==1)'><span class="text-success">${{parseFloat(item.memberprice).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</span> <span class="text-danger font-weight-normal ml-50 line-through">${{item.member_price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</span></h4>
                                            <h4 v-if='(userData.user_role=="member")&&(item.is_discount_price==0)'> <span class="text-success font-weight-normal ml-50 ">${{parseFloat(item.memberprice).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</span></h4>
                                        </div>

                                        <div v-if='item.item_type=="1"'>
                                            <h4 v-if='(userData.user_role=="nonmember")&&(item.discount_price_starts>formatDate(new Date()))&&(item.is_discount_price==1)'><span class="text-success">${{item.nonmemberprice.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</span> <span class="text-danger font-weight-normal ml-50 line-through">${{item.nonmember_price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</span></h4>
                                            <h4 v-if='(userData.user_role=="nonmember")&&(item.discount_price_starts<formatDate(new Date()))&&(item.is_discount_price==1)'> <span class="text-success font-weight-normal ml-50 ">${{item.nonmemberprice.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</span></h4>
                                            <h4 v-if='(userData.user_role=="nonmember")&&(item.is_discount_price==0)'><span class="text-success font-weight-normal ml-50 ">${{item.nonmemberprice.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</span></h4>

                                        </div>

                                        <div v-if='item.item_type=="1"'>
                                            <h4 v-if='(userData.user_role=="member")&&(item.discount_price_starts>formatDate(new Date()))&&(item.is_discount_price==1)'><span class="text-success">${{item.memberprice.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</span> <span class="text-danger font-weight-normal ml-50 line-through">${{item.member_price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</span></h4>
                                            <h4 v-if='(userData.user_role=="member")&&(item.discount_price_starts<formatDate(new Date()))&&(item.is_discount_price==1)'> <span class="text-success font-weight-normal ml-50 ">${{item.memberprice.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</span></h4>
                                            <h4 v-if='(userData.user_role=="member")&&(item.is_discount_price==0)'><span class="text-success font-weight-normal ml-50 ">${{item.memberprice.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</span></h4>
                                        </div>
                                    </div>
                                </div>
                                <b-button variant="danger" class="mt-1 remove-wishlist" @click.once="removeItem(item)">
                                    <feather-icon icon="XIcon" class="mr-50" />
                                    <span>Remove</span>
                                </b-button>

                            </div>
                        </b-card>
                    </div>

                    <!-- Checkout Options -->
                    <div class="checkout-options">
                        <b-card>
                            <h4 class="mb-1">Price Details</h4>
                            <!-- <label class="section-label mb-1">Options</label> -->
                            <!-- <b-input-group class="input-group-merge coupons">
                            <b-form-input placeholder="Coupons" />
                            <b-input-group-append is-text>
                                <span id="input-coupons" class="input-group-text text-primary cursor-pointer">Apply</span>
                            </b-input-group-append>
                        </b-input-group> -->
                            <!-- <hr> -->
                            <hr>
                            <!-- <b-form-textarea id="" placeholder="Comments" rows="3" v-model='Comments' /> -->

                            <div class="price-details">
                                <!-- <h6 class="price-title">
                                Price Details
                            </h6> -->
                                <ul class="list-unstyled">
                                    <li class="price-detail">
                                        <div class="detail-title">
                                            Subtotal
                                        </div>
                                        <div class="detail-amt" v-if='isAmount'>
                                            $0
                                        </div>
                                        <div class="detail-amt" v-if='!isAmount'>
                                            <b>
                                                <!-- ${{userData.user_role=='member'?total_member_price:total_non_member_price}} -->

                                                ${{
                                                          userData.user_role=='member'
                                                          ? total_member_price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                          : total_non_member_price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                                                 }}
                                            </b>

                                        </div>
                                    </li>

                                    <!-- <li class="price-detail">
                                    <div class="detail-title">
                                        Total Price
                                    </div>
                                    <div class="detail-amt text-primary" v-if='isAmount'>
                                        $0
                                    </div>
                                    <div class="detail-amt text-primary" v-if='!isAmount'>
                                        <b>

                                            ${{getTotalAmount(
                                                          userData.user_role=='member'
                                                          ? total_member_price
                                                          : total_non_member_price,

                                                         discount

                                                 ).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
                                        </b>

                                    </div>
                                </li> -->

                                    <li class="price-detail">
                                        <div class="detail-title">
                                            Tax
                                        </div>
                                        <div class="detail-amt" v-if='isGst && isDisc'>
                                            <b>
                                                $0
                                            </b>
                                        </div>
                                        <div class="detail-amt" v-if='!isGst && isDisc'>
                                            <b>
                                                ${{userData.user_role=='member'?member_gst:non_member_gst}}
                                            </b>
                                        </div>
                                        <div class="detail-amt" v-if='!isGst && !isDisc'>
                                            <b>
                                                ${{getGst(
                                                        getTotalAmount (
                                                          userData.user_role=='member'
                                                          ? total_member_price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                          : total_non_member_price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                 ,discount),

                                                         gst_percentage

                                                 )}}
                                            </b>
                                        </div>
                                    </li>

                                </ul>
                                <hr>
                                <ul class="list-unstyled">
                                    <li class="price-detail">
                                        <div class="detail-title detail-total">
                                            Total
                                        </div>
                                        <div class="detail-amt text-success" v-if='isAmount'>
                                            <b>
                                                $0
                                            </b>
                                        </div>
                                        <div class="detail-amt text-success" v-if='!isAmount'>
                                            <b>
                                                <!-- ${{userData.user_role=='member'?total_member_price:total_non_member_price}} -->
                                                ${{getTotalAmountGst(getTotalAmount(
                                                          userData.user_role=='member'
                                                          ? total_member_price
                                                          : total_non_member_price,

                                                         discount

                                                 ),getGst( getTotalAmount(
                                                          userData.user_role=='member'
                                                          ? total_member_price
                                                          : total_non_member_price,discount),
                                                         gst_percentage)

                                                 ).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
                                            </b>
                                        </div>
                                    </li>
                                </ul>
                                <b-button variant="secondary" block @click="validation()">
                                    Continue
                                </b-button>
                            </div>

                        </b-card>
                    </div>
                </div>

            </tab-content>

            <!-- social link -->
            <tab-content title="Payment" icon="feather icon-credit-card" class="h-75vh">
                <div class="list-view product-checkout">

                    <!-- Left Card -->
                    <b-card no-body>
                        <b-card-header class="flex-column align-items-start">
                            <b-card-title>Payment Options</b-card-title>
                            <!-- <b-card-text class="text-muted mt-25">
                            Be sure to click on correct payment option
                        </b-card-text> -->
                        </b-card-header>
                        <b-card-body>
                            <div class="mt-3">
                                <!--
                            <b-list-group flush>
                                <b-list-group-item class="px-0" v-for="item in card_list" :key="item.created">
                                    <b-form-radio v-model="paymentMethod" :value="item.id" class="">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div class="">

                                                <img src="@/assets/images/pages/visa.svg" alt="bank-logo" height="40" width="50" class="mr-50" v-if='item.brand=="Visa"' />
                                                <img src="@/assets/images/pages/mastercard.svg" alt="bank-logo" height="40" width="50" class="mr-50" v-else-if='item.brand=="MasterCard"' />
                                                <img src="@/assets/images/pages/bank.png" alt="bank-logo" height="40" width="auto" class="mr-50" v-else />
                                                <span>{{ item.card.brand }}</span>
                                                <span class="ml-2 ml-md-5">ending in {{ item.card.last4 }}</span>
                                            </div>
                                            <div class="ml-2 ml-md-5">
                                                <span>{{ item.card.exp_month }}/{{ item.card.exp_year }}</span>
                                            </div>
                                        </div>
                                    </b-form-radio>
                                </b-list-group-item>

                            </b-list-group>

                            <div v-if="card_list.length == 0">
                                <div class="not-data-table vs-table--not-data">
                                    No saved cards to show.
                                </div>
                            </div>
                            <h6 class="mt-3" style="color: rgb(184, 194, 204);">
                                Another payment method
                            </h6>
                            -->

                                <!-- vs-value="credit-debit-atmCard" -->

                                <b-form-radio v-model="paymentMethod" value="newcard">
                                    Credit/debit card
                                </b-form-radio>

                                <stripe-element-card id='card' class="stripe-element-card" ref="elementsRef" :pk="publishableKey" :amount="amount" locale="en" name="stripeElements" @token="tokenCreated" @loading="loading = $event" @element-change='enableSubmit($event)'>
                                </stripe-element-card>

                                <!-- OPTION 1 -->

                            </div>

                        </b-card-body>
                    </b-card>

                    <!-- Right Card -->
                    <div class="amount-payable checkout-options">
                        <b-card>
                            <h4 class="mb-1">Price Details</h4>
                            <hr>
                            <ul class="list-unstyled price-details">
                                <li class="price-detail">
                                    <div class="details-title">
                                        Subtotal
                                    </div>
                                    <div class="detail-amt" v-if='isAmount'>
                                        $0
                                    </div>
                                    <div class="detail-amt" v-if='!isAmount'>
                                        <b>
                                            <!-- ${{userData.user_role=='member'?total_member_price:total_non_member_price}} -->
                                            ${{
                                                          userData.user_role=='member'
                                                          ? total_member_price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                          : total_non_member_price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                                                 }}
                                        </b>

                                    </div>
                                </li>

                                <!-- <li class="price-detail">
                                <div class="details-title">
                                    Total Price
                                </div>
                               <div class="detail-amt text-primary" v-if='isAmount'>
                                        $0
                                    </div>
                                    <div class="detail-amt text-primary" v-if='!isAmount'>
                                        <b>

                                            ${{getTotalAmount(
                                                          userData.user_role=='member'
                                                          ? total_member_price
                                                          : total_non_member_price,

                                                         discount

                                                 ).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
                                        </b>

                                    </div>
                            </li> -->

                                <li class="price-detail">
                                    <div class="details-title">
                                        Tax
                                    </div>
                                    <div class="detail-amt" v-if='isGst'>
                                        $0
                                    </div>
                                    <div class="detail-amt" v-if='!isGst && isDisc'>
                                        <b>
                                            ${{userData.user_role=='member'?member_gst:non_member_gst}}
                                        </b>
                                    </div>
                                    <div class="detail-amt" v-if='!isGst && !isDisc'>
                                        <b>
                                            ${{getGst(
                                                        getTotalAmount (
                                                          userData.user_role=='member'
                                                          ? total_member_price
                                                          : total_non_member_price
                                                 ,discount),

                                                         gst_percentage

                                                 ).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
                                        </b>
                                    </div>
                                </li>
                            </ul>
                            <hr>
                            <ul class="list-unstyled price-details">
                                <li class="price-detail">
                                    <div class="detail-title detail-total">
                                        Total
                                    </div>
                                    <div class="detail-amt" v-if='isAmount'>
                                        <b>
                                            $0
                                        </b>
                                    </div>
                                    <div class="detail-amt text-success" v-if='!isAmount'>
                                        <b>
                                            ${{getTotalAmountGst(getTotalAmount(
                                                          userData.user_role=='member'
                                                          ? total_member_price
                                                          : total_non_member_price,

                                                         discount

                                                 ),getGst( getTotalAmount(
                                                          userData.user_role=='member'
                                                          ? total_member_price
                                                          : total_non_member_price

                                                 ,discount),

                                                         gst_percentage)

                                                 ).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}

                                        </b>
                                    </div>
                                </li>
                            </ul>
                            <b-button variant="secondary" block @click.once='submit()' :disabled='isDisabled'>
                                Place Order
                            </b-button>

                        </b-card>
                    </div>
                </div>

            </tab-content>
        </form-wizard>
    </b-container>

</div>
</template>

<script>
import {
    BContainer,
    BRow,
    BCol,
    BFormSpinbutton,
    BFormRadioGroup,
    BFormRadio,
    BListGroup,
    BListGroupItem,
    BMedia,
    BMediaAside,
    BMediaBody,
} from 'bootstrap-vue'
import {
    FormWizard,
    TabContent
} from 'vue-form-wizard'
import {
    ref
} from '@vue/composition-api'
import {
    StripeElementCard
} from "@vue-stripe/vue-stripe";

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueSimpleSuggest from "vue-simple-suggest";
import 'vue-simple-suggest/dist/styles.css'
import VueGoogleAutocomplete from "vue-google-autocomplete";
import Ripple from 'vue-ripple-directive'
// import {
//     avatarText
// } from '@core/utils/filter'

export default {
    components: {
        BContainer,
        BRow,
        BCol,
        BFormSpinbutton,
        BFormRadioGroup,
        BFormRadio,
        BListGroup,
        BListGroupItem,
        BMedia,
        BMediaAside,
        BMediaBody,
        // 3rd Party
        FormWizard,
        TabContent,
        VueSimpleSuggest,

        ToastificationContent,
        VueGoogleAutocomplete,
        StripeElementCard

    },
    directives: {
        Ripple,
    },
    data() {
        return {

            isLoading: false,
            fullPage: true,
            loader: "bars",
            // useSlot: false,
            product_id: '',
            order_id: '',
            // value:'',
            catSuggest: [],
            user_id: 0,
            userData: [],

            cartData: [],
            AmountData: [],
            discountAmount: '',
            isApplied: false,
            isApply: true,
            isGst: true,
            discount: 0,
            isDiscount: true,
            total_member_price: 0,
            total_non_member_price: 0,
            addressFormat: "",
            unit_no: "",
            street_no: "",
            street_name: "",
            suburb: "",

            color: "#10b401",
            total_nonmemberprice: '',
            total_memberprice: '',
            city: "",
            state: "",
            country: "",
            postal_code: "",
            Selected: '',
            Selected1: '',
            full_name: "",

            addressData: "",
            model: null,
            mode: "input",
            loading: false,
            paymentMethod: "newcard",
            publishableKey: process.env.VUE_APP_TEST_STRIPE_KEY,
            token: null,
            amount: 1000,

            isMain: false,
            address_id: 0,
            address_id2: 0,
            title: "",
            first_name: "",
            last_name: "",
            dob: "",
            gender: "",
            state: "",
            email: "",
            mobile_phone: "",

            work_phone: "",
            company: "",
            discount: 0,
            isAmount: true,

            shippingAddress: '',
            mainAddress: '',

            member_gst: '',
            non_member_gst: '',
            paid_amount: 0,
            total_amount: 0,
            Comments: '',

            tenant_name: '',
            gst_percentage: '',
            payment_date: '',
            fields: [{
                    key: "item_name",
                    label: "Name",
                },
                {
                    key: "short_description",
                    label: 'Description'

                }
            ],

            outlined: true,

            tenant_address: '',
            tenant_phone: '',
            tenant_work: '',
            file: '',
            organisation_mobile_phone: '',
            organisation_telephone: '',
            image_url: '',
            tenant_term: '',
            tenant_address: '',
            tenant_unit_no: "",
            tenant_street_name: '',
            tenant_street_number: "",
            tenant_suburb: "",
            tenant_country: "",
            tenant_city: "",
            tenant_state: "",
            tenant_pin_code: "",

            tenant: [],
            isDisc: true,
            stripe_account: '',

            isDisabled: true,
            tenant_email: '',

            token: ''
            // card_list: []

        }
    },

    created() {
        this.tenantInfo();
        this.getIteminCart();
        this.getAmountinCart();
        this.profile();

        this.getUserAddressDetailsList();

    },

    methods: {

        // Checking if the stripe card  is valid and if it is, it enables the submit button.
        enableSubmit(val) {

            if (val.complete == true) {
                this.isDisabled = false
            } else {
                this.isDisabled = true
            }

        },

        // Subtracting the value of value1 from value2 and returning the result.
        getTotalAmount(value1, value2) {
            this.paid_amount = Number(value1) - Number(value2)
            return this.paid_amount.toFixed(2)
        },
        // Calculating the total amount with GST.
        getTotalAmountWithGst(value1, value2) {
            this.total_amount = Number(value1) - Number(value2)
            return this.total_amount.toFixed(2)
        },
        // Adding the value1 and value2 and returning the total_amount.
        getTotalAmountGst(value1, value2) {
            this.total_amount = Number(value1) + Number(value2)
            return this.total_amount.toFixed(2)
        },
        // Calculating the GST amount.
        getGst(value1, value2) {
            var price = [Number(value1) * Number(value2)] / 100
            return price.toFixed(2)
        },

        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;
            return [year, month, day].join('-');
        },
        validation() {

            if (this.mainAddress.address == null && this.shippingAddress.address == null) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Please enter address",

                        icon: 'AlertCircleIcon',

                        variant: 'danger'
                    },
                })
                return false;
            } else if (this.Selected == "") {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Please select address",

                        icon: 'AlertCircleIcon',

                        variant: 'danger'
                    },
                })
                return false;
            } else if (this.Selected == "1" && (this.mainAddress.address == null)) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Please enter main address",

                        icon: 'AlertCircleIcon',

                        variant: 'danger'
                    },
                })
                return false;
            } else if (this.Selected == "2" && (this.shippingAddress.address == null)) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Please enter shipping address",

                        icon: 'AlertCircleIcon',

                        variant: 'danger'
                    },
                })
                return false;
            } else {
                this.$refs.checkoutWizard.nextTab();
            }

        },
        editMainAddress() {
            this.$bvModal.show("edit-address");
            this.addressFormat = this.mainAddress.address;
            this.unit_no = this.mainAddress.unit_no;
            this.street_no = this.mainAddress.street_number;
            this.street_name = this.mainAddress.street_name;
            this.suburb = this.mainAddress.suburb;
            this.country = this.mainAddress.country;
            this.state = this.mainAddress.state;
            this.city = this.mainAddress.city;
            this.postal_code = this.mainAddress.pin_code;
            this.address_id = this.mainAddress.address_id
            this.isMain = true;
        },

        cancelEdit() {
            this.$bvModal.hide("edit-address");
        },
        editshippingAddress() {

            this.$bvModal.show("edit-address");
            this.isMain = false;
            this.addressFormat = this.shippingAddress.address == null ? '' : this.shippingAddress.address
            this.unit_no = this.shippingAddress.unit_no == null ? '' : this.shippingAddress.unit_no;
            this.street_no = this.shippingAddress.street_number == null ? '' : this.shippingAddress.street_number;
            this.street_name = this.shippingAddress.street_name == null ? '' : this.shippingAddress.street_name;
            this.suburb = this.shippingAddress.suburb == null ? '' : this.shippingAddress.suburb;
            this.country = this.shippingAddress.country == null ? '' : this.shippingAddress.country;
            this.state = this.shippingAddress.state == null ? '' : this.shippingAddress.state;
            this.city = this.shippingAddress.city == null ? '' : this.shippingAddress.city;
            this.postal_code = this.shippingAddress.address == null ? '' : this.shippingAddress.pin_code;
            this.address_id2 = this.shippingAddress.address_id
        },

        async getUserAddressDetailsList() {
            var myHeaders = new Headers();
            // let authentication = localStorage.getItem("tokken");
            const userData = localStorage.getItem("userdetails")

            if (userData == null || undefined) {
                this.user_id = null

            } else {
                this.user_id = JSON.parse(localStorage.getItem("userdetails")).user_id;
            }
            let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;;
            // myHeaders.append("x-access-token", authentication);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            let authentication = JSON.parse(localStorage.getItem("userdetails")).jwttoken;
            myHeaders.append("x-access-token", authentication);

            var urlencoded = new URLSearchParams();

            urlencoded.append("tenant_id", tenant_id);
            urlencoded.append("user_id", this.user_id);
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow",
            };

            const rawResponse = await fetch(

                process.env.VUE_APP_BASE_URL + "userAddress",
                requestOptions
            );

            const response = await rawResponse.json();

            this.mainAddress = response.data[0]
            this.shippingAddress = response.data[1]

        },
        async insertUpdateAddress() {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            var urlencoded = new URLSearchParams();

            let authentication = JSON.parse(localStorage.getItem("userdetails")).jwttoken;
            myHeaders.append("x-access-token", authentication);

            const userData = localStorage.getItem("userdetails")

            if (userData == null || undefined) {
                this.user_id = null

            } else {
                this.user_id = JSON.parse(localStorage.getItem("userdetails")).user_id;
            }

            urlencoded.append("user_id", this.user_id);
            urlencoded.append("unit_no", this.unit_no);
            urlencoded.append("street_number", this.street_no == undefined ? '' : this.street_no);
            urlencoded.append("street_name", this.street_name);
            urlencoded.append("suburb", this.suburb);
            urlencoded.append("address", this.addressFormat);
            urlencoded.append("country", this.country);
            urlencoded.append("city", this.city);
            urlencoded.append("state", this.state);
            urlencoded.append("pin_code", this.postal_code);
            urlencoded.append("address_type", this.isMain == true ? 1 : 2);
            urlencoded.append("main_address_type", this.isMain == true ? 1 : 0);
            urlencoded.append("updatedby", 1);
            urlencoded.append("address_id", this.isMain == true ? this.address_id : this.address_id2);

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow"
            };
            const rawResponse = await fetch(

                process.env.VUE_APP_BASE_URL + "UpdateAddress",
                requestOptions
            );
            const response = await rawResponse.json();
            if (response.data[0].status == 1) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: this.isMain == true ? 'Main address updated' : 'Shipping Address updated',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                })
                this.$bvModal.hide('edit-address')
                //    this.$bvModal.hide('edit-shipping-address')
                this.getUserAddressDetailsList();

            } else {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Address failed to update',
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                    },
                })
                this.$bvModal.hide('edit-address')
                this.getUserAddressDetailsList();

            }
        },
        getAddressData: function (addressData, placeResultData) {
            this.street_no = addressData.street_number;
            this.street_name = addressData.route;
            this.suburb = addressData.locality;
            this.postal_code = addressData.postal_code;
            this.state = addressData.administrative_area_level_1;
            this.country = addressData.country;
            this.addressFormat = placeResultData.formatted_address;
        },

        async profile() {
            let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;

            let authentication = JSON.parse(localStorage.getItem("userdetails")).jwttoken;

            var myHeaders = new Headers();

            const userData = localStorage.getItem("userdetails")

            if (userData == null || undefined) {
                this.user_id = null
            } else {
                this.user_id = JSON.parse(localStorage.getItem("userdetails")).user_id;
            }

            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            myHeaders.append("x-access-token", authentication);

            var urlencoded = new URLSearchParams();

            urlencoded.append("tenant_id", tenant_id);
            urlencoded.append("user_id", this.user_id);
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow",
            };

            const rawResponse = await fetch(

                process.env.VUE_APP_BASE_URL + "profile",

                requestOptions
            );

            const response = await rawResponse.json();

            this.userData = response.data[0];

            this.isAmount = false
            this.isGst = false

            this.isLoading = false;

            this.title = this.userData.title;

            this.first_name = this.userData.first_name;
            this.full_name = this.userData.full_name;
            this.last_name = this.userData.last_name;

            this.gender = this.userData.gender;

            this.email = this.userData.email,
                this.mobile_phone = this.userData.mobile_phone;

            this.company = this.userData.company

        },

        async addQuantity(event, val) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            let authentication = JSON.parse(localStorage.getItem("userdetails")).jwttoken;
            myHeaders.append("x-access-token", authentication);
            var urlencoded = new URLSearchParams();

            urlencoded.append("item_detail_id", val.item_detail_id);
            urlencoded.append("quantity", event);
            urlencoded.append("item_id", val.item_id);
            urlencoded.append("item_type", val.item_type);

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow",
            };

            const rawResponse = await fetch(
                process.env.VUE_APP_BASE_URL + "updateQuantity",
                requestOptions
            );

            const response = await rawResponse.json();
            if (response.data[0].status == 1) {

                this.getIteminCart();
                this.getAmountinCart();
            }
        },

        async tenantInfo() {
            let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;;

            var myHeaders = new Headers();
            let authentication = JSON.parse(localStorage.getItem("userdetails")).jwttoken;
            myHeaders.append("x-access-token", authentication);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            var urlencoded = new URLSearchParams();
            urlencoded.append("tenant_id", tenant_id);
            urlencoded.append("user_type", "public_user");
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow"
            };
            const rawResponse = await fetch(

                process.env.VUE_APP_BASE_URL + "tenantInfo",
                requestOptions
            );
            const response = await rawResponse.json();

            this.tenant = response.data[0]
            this.imageUrl = this.tenant.company_logo
            this.tenant_address = this.tenant.address

            this.tenant_name = this.tenant.company_name
            this.tenant_email = this.tenant.email

            this.organisation_telephone = this.tenant.organisation_telephone,
                this.organisation_mobile_phone = this.tenant.organisation_mobile_phone
            this.image_url = this.tenant.company_logo
            this.tenant_term = this.tenant.invoice_terms_and_conditions

            this.tenant_address = this.tenant.address,
                this.tenant_unit_no = this.tenant.unit_no,
                this.tenant_street_name = this.tenant.street_name,
                this.tenant_street_number = this.tenant.street_number,
                this.tenant_suburb = this.tenant.suburb,
                this.tenant_country = this.tenant.country,
                this.tenant_city = this.tenant.city,
                this.tenant_state = this.tenant.state,
                this.tenant_pin_code = this.tenant.pin_code
            this.organisation_telephone = this.tenant.organisation_telephone,
                this.organisation_mobile_phone = this.tenant.organisation_mobile_phone

            this.gst_percentage = this.tenant.gst_in_percentage

            this.stripe_account = this.tenant.stripe_account_id

        },
        async getAmountinCart() {
            var myHeaders = new Headers();
            let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;;
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            const userData = localStorage.getItem("userdetails")

            if (userData == null || undefined) {
                this.user_id = null

            } else {
                this.user_id = JSON.parse(localStorage.getItem("userdetails")).user_id;
            }
            let authentication = JSON.parse(localStorage.getItem("userdetails")).jwttoken;
            myHeaders.append("x-access-token", authentication);
            var urlencoded = new URLSearchParams();

            urlencoded.append("tenant_id", tenant_id);
            urlencoded.append("crm_user_id", this.user_id);
            urlencoded.append("user_type", 'public_user');
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow",
            };

            const rawResponse = await fetch(

                process.env.VUE_APP_BASE_URL + "amount",

                requestOptions
            );

            const response = await rawResponse.json();
            this.AmountData = response.data[0];
            if (response.data.length == 0) {
                this.isAmount = true
                this.isGst = true
            }

            this.total_member_price = this.AmountData.memberprice
            this.total_non_member_price = this.AmountData.nonmemberprice

            this.member_gst = this.AmountData.memberprice_gst
            this.non_member_gst = this.AmountData.nonmemberprice_gst

            this.total_memberprice = this.AmountData.total_memberprice
            this.total_nonmemberprice = this.AmountData.total_nonmemberprice

        },

        async submit() {

            if (this.paymentMethod == "newcard") {
                this.$refs.elementsRef.submit();

            } else {

                this.charge = {
                    source: '',
                    amount: this.total_amount,
                    description: this.description,
                    stripeAccount: this.stripe_account,
                    card_id: this.paymentMethod
                };

                this.sendTokenToServer(this.charge);

            }
        },
        tokenCreated(token) {

            this.charge = {
                card: token.card.id,
                source: token.id,
                amount: this.total_amount,
                description: this.description,
                // stripeAccount: 'acct_1KJX4gRHuGMBGmXy'
                stripeAccount: this.stripe_account,
            };

            this.sendTokenToServer(this.charge);
        },

        async sendTokenToServer(charge) {

            this.isLoading = true;
            const currentDate = new Date();

            const day = currentDate.getDate().toString().padStart(2, '0');
            const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
            const year = currentDate.getFullYear().toString();

            const hours = currentDate.getHours().toString().padStart(2, '0');
            const minutes = currentDate.getMinutes().toString().padStart(2, '0');
            const seconds = currentDate.getSeconds().toString().padStart(2, '0');
            this.payment_date = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            let item_description = [];

            for (let i = 0; i < this.cartData.length; i++) {
                if (this.userData.user_role == "member") {
                    var amount = this.cartData[i].memberprice
                    var unit_price = this.cartData[i].unit_price_member
                    var gst = this.cartData[i].memberprice_gst
                } else {
                    var amount = this.cartData[i].nonmemberprice
                    var unit_price = this.cartData[i].unit_price_nonmember
                    var gst = this.cartData[i].nonmemberprice_gst
                }

                var item = {

                    item_name: this.cartData[i],
                    code: this.cartData[i].item_code,
                    description: this.cartData[i].item_name,
                    amount: this.total_amount,
                    item_type: this.cartData[i].item_type,
                    item_id: this.cartData[i].item_id,
                    item_detail_id: this.cartData[i].item_detail_id,
                    discount_amount: 0,
                    item_qty: this.cartData[i].item_qty,

                    amount: amount,
                    unit_price: unit_price,
                    gst: gst
                }

                item_description.push(item)
            }

            var myHeaders = new Headers();

            let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;
            const userData = localStorage.getItem("userdetails")
            if (userData == null || undefined) {
                this.user_id = null
            } else {
                this.user_id = JSON.parse(localStorage.getItem("userdetails")).user_id;
                this.token = JSON.parse(localStorage.getItem("userdetails")).jwttoken;

            }
            myHeaders.append("x-access-token", this.token);
            const formData = new FormData();
            // const userData = localStorage.getItem("userdetails")

            formData.append("tenant_id", tenant_id);
            formData.append("crm_user_id", this.user_id);

            formData.append(
                "address_list",
                JSON.stringify([{
                        address_id: this.mainAddress.address_id,
                        address: this.mainAddress.address,
                        unit_no: this.mainAddress.unit_no,
                        street_name: this.mainAddress.street_name,
                        street_number: this.mainAddress.street_number,
                        suburb: this.mainAddress.suburb,
                        country: this.mainAddress.country,
                        city: this.mainAddress.city,
                        state: this.mainAddress.state,
                        pin_code: this.mainAddress.pin_code,
                        address_type: 1,
                        main_address_type: this.Selected == '1' ? 1 : 0,
                    },
                    {
                        address_id: this.shippingAddress.address_id,
                        address: this.shippingAddress.address,
                        unit_no: this.shippingAddress.unit_no,
                        street_name: this.shippingAddress.street_name,
                        street_number: this.shippingAddress.street_number,
                        suburb: this.shippingAddress.suburb,
                        country: this.shippingAddress.country,
                        city: this.shippingAddress.city,
                        state: this.shippingAddress.state,
                        pin_code: this.shippingAddress.pin_code,
                        address_type: 2,
                        main_address_type: this.Selected == '2' ? 1 : 0,
                    },
                ])
            );

            formData.append(
                "total_amount",
                this.userData.user_role == 'member' ?
                this.AmountData.memberprice :
                this.AmountData.nonmemberprice
            );

            formData.append('total_gst_amount', this.getGst(
                this.getTotalAmount(this.userData.user_role == 'member' ?
                    this.total_member_price :
                    this.total_non_member_price, this.discount),
                this.gst_percentage

            ))

            formData.append(
                "tenant_address_list",
                JSON.stringify([{

                    address: this.tenant_address,
                    unit_no: this.tenant_unit_no,
                    street_name: this.tenant_street_name,
                    street_number: this.tenant_street_number,
                    suburb: this.tenant_suburb,
                    country: this.tenant_country,
                    city: this.tenant_city,
                    state: this.tenant_state,
                    pin_code: this.tenant_pin_code,
                    address_type: 1,
                    main_address_type: 1,
                }])
            );

            formData.append("user_id", this.user_id);
            formData.append("order_from", 'public_user');
            formData.append("title", this.title);
            formData.append("first_name", this.first_name);
            formData.append("last_name", this.last_name);
            formData.append("date_of_birth", null);

            formData.append("is_discount_applied", this.isAmount == false ? 1 : 0);

            formData.append("organisation_name", this.tenant_name);
            formData.append("payment_date", this.payment_date);

            formData.append("organisation_mobile_phone", this.organisation_mobile_phone);
            formData.append("organisation_telephone", this.organisation_telephone == null ? '' : this.organisation_telephone);

            formData.append("company_logo", this.image_url);
            formData.append("invoice_terms_and_conditions", this.tenant_term);

            formData.append("gender", this.gender);
            formData.append("state", this.state);
            formData.append("email", this.email);

            formData.append("mobile_phone", this.mobile_phone);
            formData.append("work_phone", null);
            formData.append("company", this.company);

            formData.append("updatedby", this.user_id);

            formData.append("total_discount", this.discount);
            formData.append("comments", this.Comments);
            formData.append("additional_dis_amnt", this.discount);
            formData.append("promo_id", 0);
            formData.append("payment_method", "card");
            formData.append("payment_status", this.paymentMethod == "invoice" ? 0 : "1");
            formData.append("paid_amount", this.total_amount);

            formData.append("full_name", this.userData.full_name);
            formData.append("stripe_customer_id", this.userData.stripe_customer_id);
            formData.append("description", 'Events/Products Payment');
            formData.append("token_from_stripe", charge.source);
            // formData.append("uploads[]", this.file);
            formData.append("item_description", JSON.stringify(item_description));

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formData,
                redirect: "follow",
            };

            const rawResponse = await fetch(

                process.env.VUE_APP_BASE_URL + "placeOrder",

                requestOptions
            );
            // this.isLoading = false;
            const response = await rawResponse.json();

            if (response.status == 0) {

                this.isLoading = false;
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: response.message,
                        icon: 'AlertCircleIcon',

                        variant: 'danger'
                    },
                })
                setTimeout(() => this.$router.push("/index"), 2000);
                return false;

            } else {
                this.getCart();

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Product purchased successfully',
                        icon: "CheckIcon",
                        variant: "success",
                    },
                });

                this.isLoading = false;
                setTimeout(() => this.$router.push("/index"), 2000);
                return false

            }
        },
        async getCart() {
            var myHeaders = new Headers();
            let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;;
            let authentication = JSON.parse(localStorage.getItem("userdetails")).jwttoken;
            myHeaders.append("x-access-token", authentication);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            const userData = localStorage.getItem("userdetails")
            if (userData == null || undefined) {
                this.user_id = null
            } else {
                this.user_id = JSON.parse(localStorage.getItem("userdetails")).user_id;
            }
            var urlencoded = new URLSearchParams();

            urlencoded.append("tenant_id", tenant_id);
            urlencoded.append("crm_user_id", this.user_id);
            urlencoded.append("user_type", 'public_user');
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow",
            };

            const rawResponse = await fetch(

                process.env.VUE_APP_BASE_URL + "cart",

                requestOptions
            );

            const response = await rawResponse.json();

            this.$store.commit("app/SET_CART_LENGTH", response.data.length);
            this.$store.commit("app/SET_CART_DATA", response.data);
        },

        async removeItem(value) {
            var myHeaders = new Headers();
            let authentication = JSON.parse(localStorage.getItem("userdetails")).jwttoken;
            myHeaders.append("x-access-token", authentication);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            var urlencoded = new URLSearchParams();

            urlencoded.append("item_detail_id", value.item_detail_id);
            urlencoded.append("item_id", value.item_id);
            urlencoded.append("item_type", value.item_type);

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow",
            };

            const rawResponse = await fetch(

                process.env.VUE_APP_BASE_URL + "removeFromCart",
                requestOptions
            );

            const response = await rawResponse.json();
            // this.isAmount = true
            if (response.data[0].status == 1) {

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: value.item_type == "1" ?
                            "Event removed from cart" : "Product removed from cart",

                        icon: 'CheckIcon',

                        variant: 'success'
                    },
                })
                this.getIteminCart();
                this.getAmountinCart();
            }
        },
        async getIteminCart() { 
          
                var myHeaders = new Headers();
                let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;;
                let authentication = JSON.parse(localStorage.getItem("userdetails")).jwttoken;
                myHeaders.append("x-access-token", authentication);
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
                const userData = localStorage.getItem("userdetails")
                if (userData == null || undefined) {
                    this.user_id = null
                } else {
                    this.user_id = JSON.parse(localStorage.getItem("userdetails")).user_id;
                }
                var urlencoded = new URLSearchParams();

                urlencoded.append("tenant_id", tenant_id);
                urlencoded.append("crm_user_id", this.user_id);
                urlencoded.append("user_type", 'public_user');
                var requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: urlencoded,
                    redirect: "follow",
                };

                const rawResponse = await fetch(

                    process.env.VUE_APP_BASE_URL + "cart",

                    requestOptions
                );

                const response = await rawResponse.json();
                this.cartData = response.data;

                this.$store.commit("app/SET_CART_LENGTH", this.cartData.length);
                this.$store.commit("app/SET_CART_DATA", this.cartData);
                if (this.cartData.length == 0) {
                    this.$router.push('/products')
                }
              

            
        },

    },
    setup() {
        const refFormWizard = ref(null)
        const formWizardNextStep = () => {
            refFormWizard.value.nextTab()
        }

        // ? This is just dummy details
        const checkoutDetails = ref({
            address: {
                fullName: '',
                mobile: '',
                houseNo: '',
                landmark: '',
                city: '',
                pincode: '',
                state: '',
                addressType: null,
            },
            payment: {
                cvv: '',
            },
        })

        return {
            refFormWizard,
            formWizardNextStep,

            // Dummy Details
            checkoutDetails,
            avatarText,
        }
    },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import "~vue-form-wizard/dist/vue-form-wizard.min.css";
@import "@core/scss/vue/libs/vue-select.scss";

.loader-nft {
    /* position: absolute;
    top: calc(50% - 32px);
    left: calc(50% - 32px); */
    width: 64px;
    height: 64px;
    border-radius: 50%;
    perspective: 800px;
    margin: 16px auto auto auto;
}

@keyframes loading-text {
    0% {
        content: "Minting NFT to your wallet"
    }

    25% {
        content: "Minting NFT to your wallet."
    }

    50% {
        content: "Minting NFT to your wallet.."
    }

    75% {
        content: "Minting NFT to your wallet..."
    }
}

/* loading-text */
.loader-text {
    /* position: absolute; */
    color: #ea5455;
    // background-color: #fff;
    font-weight: 600;
    border-radius: 0.25rem;
}

.loader-text:after {
    content: "Minting NFT to your wallet";
    /* font-weight: bold; */
    animation: loading-text 3s infinite;
}

.inner {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.inner.one {
    left: 0%;
    top: 0%;
    animation: rotate-one 1s linear infinite;
    border-bottom: 3px solid #8cc759;
}

.inner.two {
    right: 0%;
    top: 0%;
    animation: rotate-two 1s linear infinite;
    border-right: 3px solid #60beeb;
}

.inner.three {
    right: 0%;
    bottom: 0%;
    animation: rotate-three 1s linear infinite;
    border-top: 3px solid #ef5d74;
}

@keyframes rotate-one {
    0% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }

    100% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
}

@keyframes rotate-two {
    0% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }

    100% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
}

@keyframes rotate-three {
    0% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }

    100% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
}

@media (min-width: 992px) {
    .loader-text {
        width: 15.8rem;
    }
}

@media only screen and (max-width: 600px) {
    .loader-text {
        margin-left: 30px;
    }
}

.search-location {
    display: block;
    margin: 0 auto;
    font-size: 18px;
    font-weight: 400;
    outline: none;
    height: 45px;
    //   width:700px;
    width: 100%;

    line-height: 30px;
    text-align: center;
}

.list-view .ecommerce-card {
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
}

.list-view .ecommerce-card .item-img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
}

.list-view .ecommerce-card .item-img img {
    width: 200px;
}

.list-view .ecommerce-card .card-body {
    border-right: 1px solid rgba(60, 60, 60, 0.26);
}

.list-view .ecommerce-card .card-body {
    padding: 1.5rem 1rem;
}

.list-view .ecommerce-card .card-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.list-view .ecommerce-card .item-options {
    padding: 1rem;
}

.list-view .ecommerce-card .item-options {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.list-view {
    display: grid;
    grid-template-columns: 1fr;
}

.ecommerce-card:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    -webkit-box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%);
    box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%);
}

.ecommerce-card .item-name a {
    font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.checkout-items .ecommerce-card .item-company {
    margin-bottom: .4rem !important;
}

.list-view .ecommerce-card .item-company {
    margin: .3rem 0 .5rem;
}

.list-view .ecommerce-card .item-company {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-weight: 400;
    font-size: .875rem;
}

.item-company .company-name {
    margin-left: .25rem;
}

.list-view .ecommerce-card .item-company .company-name {
    font-weight: 600;
}

.checkout-items .ecommerce-card .item-rating {
    margin-bottom: .4rem !important;
}

.ecommerce-card .item-rating ul {
    margin-bottom: 0;
}

.checkout-items .ecommerce-card .item-quantity {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.checkout-items .ecommerce-card .delivery-date {
    margin-top: 1.2rem;
    margin-bottom: .25rem;
}

.checkout-options .price-details .price-title {
    margin-bottom: 0.75rem;
    margin-top: 1.5rem;
    font-weight: 600;
}

.checkout-options .price-details .price-detail {
    margin-bottom: 0.75rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.checkout-options .coupons input {
    padding-left: 0;
    padding-right: 0;
    border: none;
}

.checkout-options .coupons input {
    color: #6e6b7b !important;
    font-weight: 600;

}

.checkout-options .price-details .price-detail {
    margin-bottom: .75rem;
}

.checkout-options .price-details .price-detail .detail-title.detail-total {
    font-weight: 600;
}

.input-group:not(.bootstrap-touchspin):focus-within .form-control {
    box-shadow: none !important;
}

.checkout-options .coupons .input-group-append {
    margin: 0;
}

.checkout-options .coupons .input-group-text {
    padding: inherit;
}

.checkout-options .coupons .input-group-text {
    height: auto;
    font-weight: 600;
    border: none;
}

.input-group:not(.bootstrap-touchspin):focus-within {
    box-shadow: none !important;
}

.checkout-items .ecommerce-card .item-options .btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.list-view .ecommerce-card .item-options .item-wrapper {
    position: relative;
}

.item-options .item-wrapper .item-cost .item-price {
    margin-bottom: 0;
}

.list-view .ecommerce-card .item-options .item-wrapper .item-cost .item-price {
    color: #3369f2 !important;
}

.list-view .ecommerce-card .item-options .shipping {
    margin-top: 0.75rem;
}

.list-view .ecommerce-card .item-options .btn-cart {
    margin-top: 1rem;
}

div.input-group {
    padding: 0 !important;
}

.pac-container {
    z-index: 10000 !important;
}

.demo-inline-spacing>* {
    margin-top: 0.5rem;
}

@media (min-width: 992px) {
    .product-checkout.list-view {
        grid-template-columns: 2fr 1fr;
        -webkit-column-gap: 2rem;
        -moz-column-gap: 2rem;
        column-gap: 2rem;
    }
}

@media (max-width: 767.98px) {
    .list-view .ecommerce-card {
        grid-template-columns: 1fr;
    }

    .list-view .ecommerce-card .item-img {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .list-view .ecommerce-card .card-body {
        border: none;
    }
}
</style><style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
    .wizard-tab-content {
        box-shadow: none !important;
        background: transparent !important;
        padding: 0;
    }
}

#card {
    width: 550px;

}

.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.loader {
    color: #ffffff;
    /* Customize the color of the spinner */
}
</style>
